import { useEffect, VFC } from "react";

import { ArrowBack } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import ConversationsContainer from "components/chat/ConversationsContainer";
import { conversationsSliceActions } from "components/chat/store/conversationsSlice";
import useLDFlag from "hooks/useLDFlag";
import ShipperAppHeader from "pages/shipper/components/ShipperAppHeader";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

const QuoteConversationPage: VFC = () => {
  const { quoteRequestId } = useParams<{ quoteRequestId: string }>();
  const { t } = useTranslation(["chat"]);
  const enableQuoteConversationsTab = useLDFlag("enableQuoteConversationsTab");
  const history = useHistory();
  const setState = conversationsSliceActions.useSetState();

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    const selectedConversationId = params.get("selectedConversationId");

    if (!!selectedConversationId) {
      setState({ selectedConversationId: Number(selectedConversationId) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToRequestPage = () => {
    if (history.location.pathname.includes("requests")) {
      history.push(`/shipper/requests/quotes/${quoteRequestId}`);
    } else {
      history.push(`/shipper/quotes/${quoteRequestId}`);
    }
  };

  if (!enableQuoteConversationsTab) {
    history.push("/shipper/requests");
    return null;
  }

  return (
    <div className="flex flex-col h-full w-full items-start space-y-2">
      <div className="w-full bg-white">
        <ShipperAppHeader />
      </div>
      <Button
        color="primary"
        startIcon={<ArrowBack />}
        onClick={goToRequestPage}
        style={{ paddingLeft: "17.5px", paddingRight: "17.5px" }}
      >
        {t("chat:backToQuotes")}
      </Button>

      <div className="flex flex-col h-full w-full px-5 pb-5 overflow-hidden">
        <ConversationsContainer quoteRequestId={Number(quoteRequestId)} />
      </div>
    </div>
  );
};

export default QuoteConversationPage;
