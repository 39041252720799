import { ReactNode, VFC } from "react";

import { Avatar } from "@portex-pro/ui-components";
import classNames from "classnames";
import SimpleSearchView from "components/SimpleSearchView";
import Text from "components/Text";
import take from "lodash/take";
import { useTranslation } from "react-i18next";

export type DisplayConversationType = { conversationId: number; renderableContent: ReactNode[]; initials: string };

interface ConversationsViewProps {
  chatContainer: ReactNode;
  search?: string;
  onChangeSearch?: (search: string) => void;
  conversations: Array<DisplayConversationType>;
  selectedConversationId?: number;
  onChangeConversation?: (conversationId: number) => void;
}

const ConversationsView: VFC<ConversationsViewProps> = (props) => {
  const { chatContainer, search, onChangeSearch, conversations, selectedConversationId, onChangeConversation } = props;
  const { t } = useTranslation(["chat"]);

  return (
    <div className="flex flex-row w-full flex-grow overflow-hidden">
      <div className="flex flex-col max-w-[270px] w-full border-y border-l border-grey-300 bg-white">
        <div className="flex px-2 py-2.5">
          <SimpleSearchView
            placeholder={t("chat:searchChats")}
            search={search ?? ""}
            onChange={(search) => onChangeSearch?.(search)}
          />
        </div>

        <div className="flex flex-col h-full mx-2 space-y-2 overflow-scroll">
          {conversations.length === 0 ? (
            <Text size="small" typographyProps={{ color: "textSecondary", style: { alignSelf: "center" } }}>
              {t("chat:noConversations")}
            </Text>
          ) : (
            conversations.map((conversation) => {
              const { conversationId, renderableContent, initials } = conversation;

              const firstThreeContent = take(renderableContent, 3);

              return (
                <div
                  className={classNames("flex flex-row p-2 hover:bg-grey-50 rounded", {
                    "bg-blue-100": selectedConversationId === conversationId,
                  })}
                  onClick={() => onChangeConversation?.(conversationId)}
                >
                  <div className="flex mr-3.5">
                    <Avatar>{initials}</Avatar>
                  </div>

                  <div className="flex flex-col space-y-1 items-start justify-start">
                    {firstThreeContent.map((content, i) => (
                      <Text
                        size={i === 2 ? "x-small" : "small"}
                        weight={i === 0 ? "bold" : "regular"}
                        typographyProps={{ color: i === 2 ? "textSecondary" : "textPrimary" }}
                      >
                        {content}
                      </Text>
                    ))}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>

      <div className="flex flex-grow [&>div]:flex-grow">
        {!!selectedConversationId ? (
          chatContainer
        ) : (
          <div className="bg-white border border-grey-300 flex items-center justify-center">
            <Text size="x-large" weight="bold" typographyProps={{ color: "textSecondary" }}>
              {t("chat:noConversationSelected")}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConversationsView;
