import { EM_DASH } from "constants/index";

import { ArrowForward, InfoOutlined } from "@material-ui/icons";
import { Button, Status, Tooltip } from "@portex-pro/ui-components";
import LoadTypeChip from "components/LoadTypeChip";
import ModeIcon from "components/ModeIcon";
import Text from "components/Text";
import withAsync from "components/withAsync";
import useLDFlag from "hooks/useLDFlag";
import { DateTime } from "luxon";
import StateStatus from "pages/shipper/components/StateStatus";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import useLoadQuoteRequest from "../../hooks/quotes/useLoadQuoteRequest";
import QuoteRequestHeaderActions from "./QuoteRequestHeaderActions";

const QuoteRequestHeader = withAsync({
  useHook: useLoadQuoteRequest,
  Component: ({ loadedData: { quoteRequest } }) => {
    const { t } = useTranslation(["requestManagement", "common", "shipper", "chat"]);
    const enableQuoteConversationsTab = useLDFlag("enableQuoteConversationsTab");
    const history = useHistory();

    const pickupAddress = quoteRequest.stops[0]?.address;
    const dropoffAddress = quoteRequest.stops[quoteRequest.stops.length - 1]?.address;

    const pickupName = pickupAddress?.port_name ?? pickupAddress?.airport_name ?? pickupAddress?.city;
    const dropoffName = dropoffAddress?.port_name ?? dropoffAddress?.airport_name ?? dropoffAddress?.city;

    return (
      <div className="px-5 py-2 flex flex-col">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row space-x-2 self-center">
            <ModeIcon mode={quoteRequest.mode} large />

            <div className="flex flex-col">
              <Text size="large" weight="bold">
                {t("requestManagement:dispatchHeader_lane", {
                  origin: pickupName,
                  destination: dropoffName,
                })}
              </Text>

              <div className="flex flex-row space-x-2">
                <Text size="medium">{quoteRequest.portex_id}</Text>
                <StateStatus state={quoteRequest.status} />
              </div>
            </div>
          </div>

          <div className="flex flex-row space-x-11">
            <div className="flex flex-col space-y-3">
              <Text size="medium">{t("common:mode")}</Text>
              <LoadTypeChip mode={quoteRequest.mode} />
            </div>

            <div className="flex flex-col space-y-3">
              <Text size="medium">{t("shipper:pickupDate")}</Text>
              <Text size="large" weight="bold">
                {quoteRequest.stops[0].start
                  ? DateTime.fromISO(quoteRequest.stops[0].start, {
                      zone: quoteRequest.stops[0].address?.iana_timezone,
                    }).toLocaleString({
                      weekday: "short",
                      month: "short",
                      day: "numeric",
                    })
                  : EM_DASH}
              </Text>
            </div>

            <div className="flex flex-col space-y-3">
              <Text size="medium">{t("common:equipment")}</Text>
              <div className="flex flex-row items-center space-x-2">
                <Text size="large" weight="bold">
                  {quoteRequest.equipment[0]}
                </Text>
                {quoteRequest.equipment.length > 1 && (
                  <Status light palette="grey" style={{ cursor: "default", lineHeight: "1.2" }}>
                    <Tooltip
                      title={<div className="whitespace-pre-wrap">{quoteRequest.equipment.slice(1).join("\n")}</div>}
                      arrow
                    >
                      <div className="flex flex-row items-center">
                        + {quoteRequest.equipment.length - 1} <InfoOutlined style={{ marginLeft: "2px" }} />
                      </div>
                    </Tooltip>
                  </Status>
                )}
              </div>
            </div>

            <div className="self-center">
              <QuoteRequestHeaderActions />
            </div>
          </div>
        </div>

        {enableQuoteConversationsTab && (
          <div className="flex w-full items-start pt-3">
            <Button
              variant="text"
              color="primary"
              onClick={() => history.push(`/shipper/requests/quotes/${quoteRequest.id}/conversations`)}
              endIcon={<ArrowForward />}
            >
              {t("chat:viewConversations")}
            </Button>
          </div>
        )}
      </div>
    );
  },
});

export default QuoteRequestHeader;
